:root {
  --dark: #050505;
  --dark-08: #161616;
  --dark-12: #232323;
  --dark-12-50: #23232380;

  --white-20: rgba(255, 255, 255, 0.2);
  --white-12: rgba(255, 255, 255, 0.12);

  --color-app-bg: var(--dark);
  --color-wrapper-bg: var(--dark-08);

  --color-lol-blue: #6b8cff;
  --color-lol-blue-10: #6b8cff10;
  --color-lol-red: #ff8d8d;
  --color-lol-red-10: #ff8d8d10;
}
