abbr[title] {
  text-decoration: none;
}

.react-calendar button {
  margin: 0;

  border: 0;
  outline: none;
}

.react-calendar__month-view__weekdays {
  font-size: 15px;
  font-weight: 500;

  margin-bottom: 8px;

  text-align: center;
  text-transform: uppercase;

  color: var(--black);
}

.react-calendar__month-view__weekdays__weekday {
  display: flex;
  align-items: center;
  justify-content: center;

  user-select: none;
}

.react-calendar__month-view__weekdays__weekday > abbr {
  text-decoration: none !important;

  color: var(--dark-48);
}

.react-calendar__tile {
  font-size: 15px;
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: center;

  padding-bottom: 8px;

  cursor: pointer;

  background: none;
}

.react-calendar__tile:disabled {
  cursor: initial;
}

.react-calendar__month-view__weekdays__weekday > abbr {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;

  width: 40px;
  height: 40px;

  text-align: center;
  text-decoration: none;
}

.react-calendar__tile > abbr {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;

  width: 40px;
  height: 40px;

  text-align: center;

  color: var(--white);
  border-radius: 50%;
}

.react-calendar__month-view__days__day--neighboringMonth > abbr {
  color: var(--dark-20);
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  /* background-color: #ffffa9; */
}

.react-calendar__tile--hasActive {
  background-color: var(--red);
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background-color: var(--red);
}

.react-calendar__tile--active > abbr {
  flex-shrink: 0;

  color: var(--white);
  border-radius: 6px;
  background-color: var(--dark-20);
}
