
/*
 * Sizes for the layout elements
 */

:root {
  --app-navigation-width: 280px;
  --app-right-section-width: 320px;
  --app-right-section-gap: 48px;
  --app-center-content-width: 762px;
  --app-content-width: calc(var(--app-center-content-width)
  + var(--app-right-section-gap)
  + var(--app-right-section-width));

  --app-header-height: 72px;
  --max-welcome-content-width: 1064px;
  --app-content-list-right-padding: 0;
  --app-content-list-left-padding: 0;
}

@media (min-width: 1900px) {
  :root {
    --app-center-content-width: 762px;
    --app-right-section-width: 320px;
    --app-right-section-gap: 48px;
    --app-content-list-right-padding: 71px;
    --app-content-list-left-padding: 31px;
    --max-welcome-content-width: 1064px;
  }
}

@media (min-width: 1366px) and (max-width: 1899px) {
  :root {
    --app-center-content-width: 652px;
    --app-navigation-width: 220px;
    --app-right-section-width: 270px;
    --app-right-section-gap: 32px;
  }
}
/*
@media (min-width: 1024px) and (max-width: 1365px) {
  :root {
    --app-center-content-width: 550px;
    --app-navigation-width: 200px;
    --app-right-section-width: 242px;
    --app-right-section-gap: 16px;
  }
}

@media (max-width: 1024px) {
  :root {
    --app-center-content-width: 550px;
    --app-navigation-width: 200px;
    --app-right-section-width: 242px;
    --app-right-section-gap: 16px;
  }
} */

/* TEMP: Remove layout less than 1366 */
@media (max-width: 1365px) {
  :root {
    --app-center-content-width: 652px;
    --app-navigation-width: 220px;
    --app-right-section-width: 270px;
    --app-right-section-gap: 32px;
  }
}
