/* DEPRECATED. Destruct */
:root {
  --inset-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.04);

  --mobile-horizontal-padding: 20px;
  --mobile-height: 100%; /* Changed with JS */

  --retina-scale: 0;
  --scrollbar-width: 16px;

  --slot-width: 179px;
  --slot-height: 228px;

  --yellow: rgb(255, 190, 0);
  --yellow-20: rgba(255, 190, 0, 0.2);
  --yellow-48: rgba(255, 190, 0, 0.48);
  --red: rgb(255, 0, 107);
  --red-70: rgba(255, 0, 107, 0.7);
  --dark-red: rgb(164, 51, 43);
  --green: rgb(36, 242, 77);
  --green-10: rgb(36, 242, 77, 0.1);
  --green-20: rgb(36, 242, 77, 0.2);
  --green-48: rgb(36, 242, 77, 0.48);

  --green-16: rgb(36, 242, 77, 0.16);

  --white: rgb(255, 255, 255);
  --white-64: rgba(255, 255, 255, 0.64);
  --white-48: rgba(255, 255, 255, 0.48);
  --white-32: rgba(255, 255, 255, 0.32);
  --white-80: rgba(255, 255, 255, 0.8);
  --white-60: rgba(255, 255, 255, 0.6);
  --white-40: rgba(255, 255, 255, 0.4);
  --white-16: rgba(255, 255, 255, 0.16);
  --white-10: rgba(255, 255, 255, 0.1);
  --white-8: rgba(255, 255, 255, 0.08);
  --white-5: rgba(255, 255, 255, 0.05);

  --black: rgb(0, 0, 0);
  --black-80: rgba(0, 0, 0, 0.8);
  --black-75: rgba(0, 0, 0, 0.75);
  --black-50: rgba(0, 0, 0, 0.5);
  --black-40: rgba(0, 0, 0, 0.4);
  --black-30: rgba(0, 0, 0, 0.3);
  --black-16: rgba(0, 0, 0, 0.16);
  --black-10: rgba(0, 0, 0, 0.1);
  --black-8: rgba(0, 0, 0, 0.08);
  --black-5: rgba(0, 0, 0, 0.05);

  --dark: #050505;
  --dark-06: #141414;
  --dark-08: #161616;
  --dark-12: #232323;
  --dark-20: #373737;
  --dark-32: #555;
  --dark-48: #7d7d7d;
  --dark-64: #a5a5a5;

  --overlay: rgba(5, 5, 5, 0.88);

  --discord-color: #404eed;
  --discord-hover-color: #5f6bf0;
  --battlenet-color: #148eff;
  --battlenet-hover-color: #4ba2f3;
  --twitch-color: #5c16c5;
  --twitch-hover-color: #772ce8;

  --layer-1: 9;
  --layer-2: 99;
  --layer-3: 999;
  --layer-4: 9999;
  --layer-5: 99999;

  --color-icon: var(--white);
  --color-input-bg: rgba(142, 142, 255, 0.1);
  --main-green: #24f24d;

  /* Capture */
  --padding-top: 69px;
  --padding-bottom: 0px;

  --height-topic-header: 65px;
  --height-message-box: 72px;

  --border-radius: 8px;
  --color-background: var(--dark);
  --color-border: var(--black-5);

  --icon-color: var(--white);
}

:root {
  --min-content-wrapper-width: 794px;

  --modal-footer-height-px: 112px;
  --theme-header-height-px: 128px;
  --full-header-height-px: calc(var(--app-header-height) + var(--theme-header-height-px));
  --navbar-indent: 8px;

  --header-z: 999;

  --game-card-width: 179px;
  --game-card-small-height: 204px;
  --game-card-height: 253px;

  --modal-logo-small-width: 52px;
}
