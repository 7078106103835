/* @import url('normalize.css'); */
@tailwind base;

@tailwind components;

@tailwind utilities;

@import url('components/Calendar/Calendar.css');

* {
  font-family: inherit;

  box-sizing: border-box;
}

html {
  background-color: var(--color-app-bg);

  scrollbar-gutter: stable;
}

input {
  margin: 0;
  padding: 0;

  border: none;
  outline: none;
}

button {
  margin: 0;
  padding: 0;

  border: none;
  outline: none;
}

body {
  overflow-wrap: break-word;

  font-family: -apple-system, 'SF Pro Display', 'SF Pro', BlinkMacSystemFont, '.SFNSText-Regular', system-ui, sans-serif, arial, Tahoma, verdana;
  font-size: 15px;
  line-height: 1.4em;

  min-height: 100vh;
  margin: 0;

  color: var(--white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#__next {
  display: flex;
  justify-content: center;

  width: 100%;
  min-height: inherit;
}

body::-webkit-scrollbar-thumb {
  opacity: 0.8;
  border-radius: 100px;
}

input {
  padding: 0;
}

a {
  text-decoration: none;

  color: var(--white);
}

h1,
h2,
h3,
h4,
h5,
p,
div {
  margin: 0;
  padding: 0;

  color: var(--white);
}

body:after {
  position: absolute;
  z-index: -1;

  overflow: hidden;

  width: 0;
  height: 0;

  content: var(--all-backgrounds);
}

#modal-root {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}